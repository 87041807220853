import React from 'react'
import Layout from '../components/layout'
import { graphql, StaticQuery } from 'gatsby'
import {
	FaMapMarkerAlt,
	FaEnvelope,
	FaPhone,
	FaPaintBrush,
} from 'react-icons/fa'
import Footer from '../components/footer'

const Contact = () => (
	<StaticQuery
		query={graphql`
			{
				allWordpressPage(filter: { title: { eq: "Contact" } }) {
					edges {
						node {
							acf {
								name
								location
								email
								phone
								image {
									source_url
								}
							}
						}
					}
				}
			}
		`}
		render={(props) => (
			<Layout>
				<div className='contact-body'>
					<div className='columns'>
						<div className='column is-4-desktop is-offset-2-desktop is-6-tablet is-offset-0-tablet is-12-mobile is-offset-0-mobile'>
							<div className='contact-left'>
								<h1>{props.allWordpressPage.edges[0].node.acf.name}</h1>
								<img
									className='about-image'
									alt='Contact'
									src={
										props.allWordpressPage.edges[0].node.acf.image.source_url
									}
								/>
								<h3>
									<FaMapMarkerAlt className='contact-icon' />{' '}
									{props.allWordpressPage.edges[0].node.acf.location}
								</h3>
								<h3>
									<FaEnvelope className='contact-icon' />{' '}
									<a
										href='mailto:Aliffman@gmail.com'
										target='_blank'
										rel='noopener noreferrer'>
										{props.allWordpressPage.edges[0].node.acf.email}
									</a>
								</h3>
								<h3>
									<FaPhone className='contact-icon' />{' '}
									<a href='tel:2164095185'>
										{props.allWordpressPage.edges[0].node.acf.phone}
									</a>
								</h3>
								<h3>
									<FaPaintBrush className='contact-icon' />{' '}
									<a
										href='https://www.allisonliffmanart.com/'
										target='_blank'
										rel='noreferrer'>
										AllisonLiffmanArt.com
									</a>
								</h3>
							</div>
						</div>
						<div className='column is-4-desktop is-offset-1-desktop is-6-tablet is-offset-0-tablet is-12-mobile is-offset-0-mobile contact-form'>
							<form name='contact' netlify netlify-honeypot='bot-field' hidden>
								<input type='text' name='name' />
								<input type='email' name='email' />
								<select name='How did you hear about Allison Liffman Photography?[]'>
									<option value='Select'>Select One</option>
									<option value='Instagram'>Instagram</option>
									<option value='Facebook'>Facebook</option>
									<option value='Web Search'>Web search </option>
									<option value='Family Friend'>Family / Friend</option>
									<option value='Mailer'>Mailer</option>
									<option value='Other'>Other</option>
								</select>
								<textarea name='message'></textarea>
							</form>
							<form name='contact' method='POST' data-netlify='true' netlify>
								<input type='hidden' name='form-name' value='contact' />
								<p>
									<label>
										Your Name: <input type='text' name='name' />
									</label>
								</p>
								<p>
									<label>
										Your Email: <input type='email' name='email' />
									</label>
								</p>
								<p>
									<label>
										Message: <textarea name='message'></textarea>
									</label>
								</p>
								<p>
									<label>
										How did you hear about Allison Liffman Photography?
										<select name='How did you hear about Allison Liffman Photography?[]'>
											<option value='Select'>Select One</option>
											<option value='Instagram'>Instagram</option>
											<option value='Facebook'>Facebook</option>
											<option value='Web Search '>Web search </option>
											<option value='Family Friend'>Family / Friend</option>
											<option value='Mailer'>Mailer</option>
											<option value='Other'>Other</option>
										</select>
									</label>
								</p>
								<div>
									<button type='submit'>Send</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<Footer />
			</Layout>
		)}
	/>
)

export default Contact
